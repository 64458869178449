import geral from './geral.config';

const configs = {
    geral: {
        ...geral
    },
    titles: {
        SECTOR: 'Alterar perfil'
    },
    fields: {
        name: {
            LABEL: 'Nome',
            MIN_LENGTH: 5,
            icon: {
                USER: 'face',
                MANAGER: 'account_circle',
                COMPANY: 'school',
                get(role) {
                    return this[role];
                }
            }
        },
        email: {
            LABEL: 'Email'
        },
        currentPassword: {
            LABEL: 'Senha atual',
            MIN_LENGTH: 6,
            icon: {
                TEXT: 'visibility_off',
                PASSWORD: 'visibility',
                get(isTypeFieldPassword) {
                    return isTypeFieldPassword ? this.PASSWORD : this.TEXT;
                }
            }
        }
    },
    buttons: {
        SUBMIT: 'Alterar perfil'
    },
    messages: {
        name: {
            alerts: {
                MANDATORY_NAME: 'Nome obrigatório!',
                CHECK_NAME: 'Preencha o campo nome!'
            },
            errors: {
                NAME_VALID: 'Insira um nome válido!',
                CHECK_NAME: 'Digite seu nome e sobrenome!'
            }
        },
        email: {
            alerts: {
                MANDATORY_EMAIL: 'Email obrigatório!'
            },
            errors: {
                CHECK_EMAIL: 'Preencha o campo email!',
                EMAIL_VALID: 'Insira um email válido!'
            }
        },
        currentPassword: {
            alerts: {
                MANDATORY_CPASSWORD: 'Senha atual obrigatória!',
                MIN_LENGTH_CPASSWORD: (minLength) => {
                    return `Insira pelo menos ${minLength} caracteres!`;
                }
            },
            errors: {
                CHECK_CURRENT_PASSWORD: 'Preencha o campo senha atual!',
                CURRENT_PASSWORD_VALID: 'Insira uma senha atual válida!'
            }
        }
    }
};

export default configs;
